import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useLocale } from 'hooks/locales/useLocale'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Button } from '@vizeat/components/es6/components/Button'
import { Text } from '@vizeat/components/es6/components/Text'
import { NotificationBadge } from '@vizeat/components/es6/components/NotificationBadge'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { NotificationItem } from './NotificationItem'
import { InfiniteScroll } from '@vizeat/components/es6/components/InfiniteScroll'
import { Divider } from '@vizeat/components/es6/components/Divider'
import { getDetails } from 'helpers/notifications'

export function MarkAllAsReadButton({ onClick }) {
  const { t } = useTranslation()

  return (
    <Button
      clear
      onClick={onClick}
      css={`
        padding: 0;
        font-size: 12px;
      `}
    >
      {t('Notifications::Mark all as read')}
    </Button>
  )
}

MarkAllAsReadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export function NotificationsList({
  loadMore,
  markAsRead,
  markAllAsRead,
  closeModal,
  notifications,
  unread,
  offset,
  size,
  total,
}) {
  const { t } = useTranslation()
  const router = useRouter()
  const isTabletOrHigher = useMediaQuery('tablet')
  const canLoadMore = offset + size < total
  const { locale } = useLocale()

  const sortedNotifications = useMemo(
    () => notifications.sort((a, b) => moment.utc(b.created_at).diff(moment.utc(a.created_at))),
    [notifications],
  )

  function handleClick(notification) {
    const details = getDetails(notification)
    markAsRead(notification)

    if (details.path) {
      if (details.externalLink) {
        window.location = details.path
      } else {
        router.push(details.path)
        closeModal?.()
      }
    }
  }

  return (
    <Flex
      flexDirection='column'
      minWidth='280px'
      position={{ tablet: 'relative', default: 'static' }}
      margin={{ tablet: '0', default: '-16px' }}
    >
      {/*  Header */}
      {isTabletOrHigher && (
        <Box p={{ tablet: '8px', default: '0' }} flex='none'>
          <Flex flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Box>
              <Text as='span'>{t('Notifications::Notifications')}</Text>
              {unread > 0 && <NotificationBadge>{unread}</NotificationBadge>}
            </Box>

            {unread > 0 && <MarkAllAsReadButton onClick={markAllAsRead} />}
          </Flex>
        </Box>
      )}

      <Divider width='100%' my='0' color='lighterGray' />

      {/* Content */}
      <Box
        height={{ tablet: '300px', default: 'auto' }}
        width={{ tablet: 'auto', default: 'auto' }}
        minWidth='320px'
        overflowY={{ tablet: 'auto', default: 'visible' }}
      >
        {sortedNotifications.valueSeq().map((notification, i) => (
          <div key={i}>
            <NotificationItem
              notification={notification}
              locale={locale}
              handleClick={() => handleClick(notification)}
              allRead={unread === 0}
            />
            <Divider my='0' color='lighterGray' />
          </div>
        ))}
        {sortedNotifications.size === 0 && (
          <Box textAlign='center' p='8px'>
            <Text as='span' color='darkGray'>
              {t('Notifications::You have no notifications')}
            </Text>
          </Box>
        )}

        <InfiniteScroll canLoadMore={canLoadMore} onLoadMore={loadMore} />
      </Box>

      {/* Footer */}
      <Box
        position='sticky'
        bottom={{ tablet: '0', default: '-16px' }}
        p={{ tablet: '8px', default: '16px' }}
        textAlign='center'
        borderRadius='md'
        bg='white'
      >
        <Text as='span' color='mediumGray' size={isTabletOrHigher ? 'xs' : 's'}>
          {canLoadMore ? t('Notifications::Scroll to load more') : t('Notifications::All notifications loaded')}
        </Text>
      </Box>
    </Flex>
  )
}

NotificationsList.propTypes = {
  loadMore: PropTypes.func.isRequired,
  markAsRead: PropTypes.func.isRequired,
  markAllAsRead: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
  notifications: PropTypes.immutable.map.isRequired,
  unread: PropTypes.number,
  offset: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
}

NotificationsList.defaultProps = {
  unread: 0,
  offset: 0,
  size: 0,
  total: 0,
  closeModal: undefined,
}
