import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { Box } from '@vizeat/components/es6/components/Box'
import { LegacyDropdown } from '@vizeat/components/es6/components/LegacyDropdown'
import { Toggle } from './Toggle'
import { MarkAllAsReadButton, NotificationsList } from './NotificationsList.jsx'
import {
  fetchNotifications,
  setNotificationAsRead,
  markAllNotificationsAsRead,
  clearNotifications,
} from 'redux/actions'
import {
  fetchingNotifications,
  getNotifications,
  getNotificationsUnread,
  getNotificationsOffset,
  getNotificationsTotal,
  getNotificationsSize,
  getIsCurrentUserLoggedIn,
} from 'redux/selectors'
import PropTypes from 'helpers/proptypes'
import { MobileModal, MobileModalTitle } from '@vizeat/components/es6/components/MobileModal'
import { useTranslation } from 'next-i18next'
import { NotificationBadge } from '@vizeat/components/es6/components/NotificationBadge'

export function NotificationsDropdown({ as, icon, styles, badgeStyles }) {
  const dispatch = useDispatch()
  const isFetchingNotifications = useSelector(fetchingNotifications)
  const notifications = useSelector(getNotifications)
  const unread = useSelector(getNotificationsUnread)
  const total = useSelector(getNotificationsTotal)
  const offset = useSelector(getNotificationsOffset)
  const size = useSelector(getNotificationsSize)
  const isCurrentUserLoggedIn = useSelector(getIsCurrentUserLoggedIn)
  const isTabletOrHigher = useMediaQuery('tablet')
  const { t } = useTranslation()

  const [state, setState] = useState({
    showModal: false,
    scrollOffset: 0,
  })

  const handleFetchNotifications = useCallback(
    (offset = 0) => {
      if (!isFetchingNotifications) {
        dispatch(fetchNotifications(offset))
      }
    },
    [dispatch, isFetchingNotifications],
  )

  useEffect(() => {
    if (isCurrentUserLoggedIn) handleFetchNotifications(offset)
  }, [offset, handleFetchNotifications, isCurrentUserLoggedIn])

  useEffect(
    () => () => {
      dispatch(clearNotifications())
    },
    [dispatch],
  )

  const markAsRead = useCallback(
    (notification) => {
      if (isCurrentUserLoggedIn && (!notification.read_at || unread)) {
        dispatch(setNotificationAsRead(notification.id))
        handleFetchNotifications()
      }
    },
    [dispatch, handleFetchNotifications, isCurrentUserLoggedIn, unread],
  )

  const markAllAsRead = useCallback(() => {
    dispatch(markAllNotificationsAsRead())
  }, [dispatch])

  const loadMore = useCallback(() => {
    setState((prev) => {
      const scrollOffset = prev.scrollOffset + size
      handleFetchNotifications(scrollOffset)
      return { ...prev, scrollOffset }
    })
  }, [handleFetchNotifications, size])

  const handleToggleModal = useCallback(() => {
    setState((prev) => ({ ...prev, showModal: !prev.showModal }))
  }, [])

  if (!isCurrentUserLoggedIn) return null

  if (isTabletOrHigher) {
    return (
      <Box
        as={as}
        css={`
          div[class^='Dropdown__BaseMenu'] {
            bottom: 0;
          }
        `}
      >
        <LegacyDropdown isLeft trigger={<Toggle unread={unread} />}>
          <NotificationsList
            notifications={notifications}
            loadMore={loadMore}
            markAsRead={markAsRead}
            markAllAsRead={markAllAsRead}
            unread={unread}
            offset={state.scrollOffset}
            size={size}
            total={total}
          />
        </LegacyDropdown>
      </Box>
    )
  }

  return (
    <Box as={as}>
      <Toggle unread={unread} onClick={handleToggleModal} icon={icon} styles={styles} badgeStyles={badgeStyles} />

      <MobileModal
        show={state.showModal}
        onHide={handleToggleModal}
        variant='bottomToTop'
        Header={
          <>
            <MobileModalTitle
              styles={`
                padding-right: 0;
              `}
              content={t('Notifications::Notifications')}
            />
            {unread > 0 && (
              <>
                <NotificationBadge
                  styles={`
                    margin-left: 0;
                  `}
                >
                  {unread}
                </NotificationBadge>
                <MarkAllAsReadButton onClick={markAllAsRead} />
              </>
            )}
          </>
        }
      >
        <NotificationsList
          notifications={notifications}
          loadMore={loadMore}
          markAsRead={markAsRead}
          markAllAsRead={markAllAsRead}
          unread={unread}
          offset={state.scrollOffset}
          size={size}
          total={total}
        />
      </MobileModal>
    </Box>
  )
}

NotificationsDropdown.propTypes = {
  as: PropTypes.string,
  styles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  badgeStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  icon: PropTypes.node,
}

NotificationsDropdown.defaultProps = {
  as: undefined,
  styles: undefined,
  badgeStyles: undefined,
  icon: null,
}
