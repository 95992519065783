import { cast } from 'helpers/schema'
import * as yup from 'yup'

export const amenitySchema = yup.object({
  id: yup.number().optional(),
  picto: yup.string().nullable().default(null),
  title: yup.string().nullable().default(null),
  original_title: yup.string().required(),
})

export function create(data) {
  return cast(amenitySchema, data)
}

export const emptyAmenity = create()
