// libs
import React from 'react'
import PropTypes from 'helpers/proptypes'
import styled from 'styled-components'
// components
import { NotificationBadge } from '@vizeat/components/es6/components/NotificationBadge'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { IconBell } from '@vizeat/components/es6/assets/icons'

const Wrapper = styled((props) => <Flex as='button' {...props} />)`
  background: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
  position: relative;
  margin-right: 8px;
  padding: 0 8px;
  ${({ theme }) => theme.media.tablet`
    padding: 0 16px;
  `}
`

const StyledNotificationBadge = styled((props) => <NotificationBadge floating {...props} />)`
  top: -14px;
  right: -2px;
`

export function Toggle({ unread, onClick, icon, styles, badgeStyles }) {
  return (
    <Wrapper onClick={onClick} css={styles}>
      {icon || <IconBell size='20px' />}
      {unread > 0 && <StyledNotificationBadge css={badgeStyles}>{unread}</StyledNotificationBadge>}
    </Wrapper>
  )
}

Toggle.propTypes = {
  unread: PropTypes.number.isRequired,
  styles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  badgeStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onClick: PropTypes.func,
  icon: PropTypes.node,
}

Toggle.defaultProps = {
  styles: undefined,
  badgeStyles: undefined,
  onClick: undefined,
  icon: null,
}
