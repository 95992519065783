import * as yup from 'yup'
import { amenitySchema } from './amenity'
import { cast } from 'helpers/schema'

export const placeSchema = yup.object({
  id: yup.number().optional(),
  user_id: yup.number().required(),
  administrative_area_level_1: yup.string().nullable().default(null),
  administrative_area_level_2: yup.string().nullable().default(null),
  additional_info: yup.string().nullable().default(null),
  address: yup.string().nullable().default(null),
  amenities_ids: yup.array().of(yup.number()).default([]),
  amenities: yup.array().of(amenitySchema).default([]),
  coordinates: yup.object({
    latitude: yup.number().nullable().default(null),
    longitude: yup.number().nullable().default(null),
    zoom: yup.number().nullable().default(null),
    radius: yup.number().nullable().default(null),
    geometry: yup.object({}),
  }),
  country: yup.string().required(),
  country_iso: yup.string().required(),
  cover_id: yup.number().nullable().default(null),
  formatted: yup.string().nullable().default(null),
  provider: yup.string().nullable().default(null),
  provider_id: yup.string().nullable().default(null),
  location_code: yup.string().nullable().default(null),
  lat: yup.number().nullable().default(null),
  lng: yup.number().nullable().default(null),
  locality: yup.string().nullable().default(null),
  postal_code: yup.string().nullable().default(null),
  route: yup.string().nullable().default(null),
  street_number: yup.string().nullable().default(null),
  timezone: yup.string().nullable().default(null),
  title: yup.string().nullable().default(null),
  created_at: yup.string().nullable().default(null),
  updated_at: yup.string().nullable().default(null),
})

export function create(data) {
  return cast(placeSchema, data)
}

export const emptyPlace = create()
