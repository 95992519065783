import { screenApi } from 'apis'

export async function getFoods() {
  const { data } = await screenApi.get('/foods', { params: { activated: true } })
  return data.foods
}

export async function getDiets() {
  const { data } = await screenApi.get('/diets')
  return data.diets
}
