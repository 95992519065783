import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'next-i18next'
import { useLandingPagesLinks, useMegaMenuLinks } from 'hooks/header'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getCurrentUser, getIsCurrentUserLoggedIn } from 'redux/selectors'
import { commonHeaderPropTypes, commonHeaderDefaultProps, NavItem } from '../styled'
import { LocaleAndCurrency } from './LocaleAndCurrency'
import { MegaMenu } from '../MegaMenu'
import { UserDropdown } from './UserDropdown'
import { InboxMenuItem } from '../InboxMenuItem'
import { NotificationsDropdown } from '../NotificationsDropdown'
import { AuthButtons } from '../AuthButtons'
import { urlFactory } from 'helpers/url'

const DesktopNavWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  flex-shrink: 0;
  padding: 0;
  margin: 0;
  list-style: none;
`

export function DesktopNav({
  hideAllNavItems,
  hideExperiences,
  hidePrivateEvent,
  hideResumeApplication,
  isTransparent,
  shouldDisplayBecomeAHostButton,
}) {
  const { t } = useTranslation()
  const { masterchef, becomeHost } = useLandingPagesLinks({ shouldDisplayBecomeAHostButton })
  const megamenuLinks = useMegaMenuLinks({ hideExperiences, hidePrivateEvent })

  const currentUser = useSelector(getCurrentUser)
  const isCurrentUserLoggedIn = useSelector(getIsCurrentUserLoggedIn)

  const shouldShowResumeApplication = !hideResumeApplication && currentUser.host_application_id && !currentUser.isHost

  if (hideAllNavItems)
    return (
      <DesktopNavWrapper>
        <LocaleAndCurrency transparent={isTransparent} />
        <UserDropdown hideAllNavItems isTransparent={isTransparent} />
      </DesktopNavWrapper>
    )

  return (
    <DesktopNavWrapper>
      {masterchef && (
        <li>
          <NavItem href={masterchef.href} transparent={isTransparent} css='display: flex; align-items: center;'>
            {masterchef.text}
          </NavItem>
        </li>
      )}

      {megamenuLinks.map(({ title, prismicKey, fallbackHref }) => (
        <li key={prismicKey}>
          <MegaMenu prismicKey={prismicKey} title={title} fallbackHref={fallbackHref} transparent={isTransparent} />
        </li>
      ))}

      {shouldShowResumeApplication && (
        <li>
          <NavItem
            href={urlFactory.dashboard.hostApplication.resume()}
            target='_blank'
            rel='noopener noreferrer'
            transparent={isTransparent}
            highlighted
          >
            {t('Header::Resume application')}
          </NavItem>
        </li>
      )}

      <LocaleAndCurrency transparent={isTransparent} />

      <UserDropdown isTransparent={isTransparent} />

      <InboxMenuItem as='li' transparent={isTransparent} />

      <NotificationsDropdown as='li' />

      <AuthButtons isTransparent={isTransparent} signupText={t('Header::Sign up')} />

      {becomeHost && (
        <li>
          <NavItem href={becomeHost.href} transparent={isTransparent} highlighted={isCurrentUserLoggedIn}>
            {becomeHost.text}
          </NavItem>
        </li>
      )}
    </DesktopNavWrapper>
  )
}

DesktopNav.propTypes = {
  ...commonHeaderPropTypes,
  shouldDisplayBecomeAHostButton: PropTypes.bool.isRequired,
}

DesktopNav.defaultProps = commonHeaderDefaultProps
