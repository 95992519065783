import React, { useMemo } from 'react'
import PropTypes from 'helpers/proptypes'
import { useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Image } from '@vizeat/components/es6/components/Image'
import { Text } from '@vizeat/components/es6/components/Text'
import { Grid } from '@vizeat/components/es6/components/Grid'
import * as icons from '@vizeat/components/es6/assets/icons'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { SmartFooterLink } from './SmartFooterLink'
import { useIsRtl } from 'hooks/locales/useIsRtl'
import { getFacebookLink, getInstagramLink } from 'redux/selectors'

const SOCIALS_ICONS = {
  facebook: 'IconFacebook',
  instagram: 'IconInstagram',
  twitter: 'IconX',
  wechat: 'IconWechatUnfilled',
  linkedin: 'IconLinkedin',
  tiktok: 'IconTiktok',
}

export function SocialLink({ type, href }) {
  const IconComponent = icons[SOCIALS_ICONS[type]]

  return (
    <SmartFooterLink href={href} target='_blank' rel='noopener noreferrer'>
      <IconComponent color='white' fontSize='24px' />
    </SmartFooterLink>
  )
}

SocialLink.propTypes = {
  type: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
}

export function SocialLinks({ hideExternalLinks }) {
  const { t } = useTranslation()
  const { isRTL } = useIsRtl()
  const isMobile = !useMediaQuery('tablet')
  const instagramLink = useSelector(getInstagramLink)
  const facebookLink = useSelector(getFacebookLink)

  const socialLinkEntries = useMemo(
    () =>
      Object.entries({
        facebook: facebookLink,
        twitter: 'https://twitter.com/Eatwith',
        instagram: instagramLink,
        wechat: 'https://eatwith.net.cn',
        linkedin: 'https://www.linkedin.com/company/eatwith/',
        tiktok: 'https://www.tiktok.com/@eatwithlocals',
      }),
    [facebookLink, instagramLink],
  )

  return (
    <Box textAlign='center'>
      {!hideExternalLinks && (
        <>
          {isMobile && (
            <Flex justifyContent='center' gap='4px'>
              <Text as='span' color='white'>
                {t('Footer::Follow us')}
              </Text>
              <Image
                width='36px'
                src='/assets/images/arrow-spiral.png'
                alt=''
                css={`
                  transform: scaleX(${isRTL ? '-1' : '1'}) scaleY(-1) rotate(-15deg);
                  margin: 12px 0;
                `}
              />
            </Flex>
          )}

          <Grid templateColumns='repeat(6, 1fr)' justify-content='space-between' width='300px' margin='auto'>
            {socialLinkEntries.map(([type, href]) => (
              <SocialLink key={type} type={type} href={href} />
            ))}
          </Grid>
        </>
      )}
    </Box>
  )
}

SocialLinks.propTypes = {
  hideExternalLinks: PropTypes.bool,
}

SocialLinks.defaultProps = {
  hideExternalLinks: false,
}
