import React from 'react'
import PropTypes from 'helpers/proptypes'
import moment from 'moment'
import styled from 'styled-components'
import { getDetails } from 'helpers/notifications'
import { Box } from '@vizeat/components/es6/components/Box'
import { Flex } from '@vizeat/components/es6/components/Flex'
import { Image } from '@vizeat/components/es6/components/Image'
import { Text } from '@vizeat/components/es6/components/Text'

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    read_at: PropTypes.string,
    created_at: PropTypes.string,
    body: PropTypes.string,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  locale: PropTypes.string,
  allRead: PropTypes.bool,
}

NotificationItem.defaultProps = {
  locale: undefined,
  allRead: false,
}

const StyledContainer = styled(Flex)`
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.lighterGray};
    color: ${({ theme }) => theme.colors.darkGray};
  }
`

const StyledIcon = styled(Image)`
  height: 100%;
  width: 100%;
  padding: 4px;
`

export function NotificationItem({ notification, handleClick, locale, allRead }) {
  const details = getDetails(notification)
  const isRead = notification.read_at || allRead

  moment.locale(locale) // set locale for 'timeAgo'

  return (
    <StyledContainer bg={isRead ? 'lighterGray' : 'white'} p='12px' width='100%' isRead={isRead} onClick={handleClick}>
      <Flex flexDirection='column' justifyContent='center' alignItems='center' minWidth='24px' mr='8px'>
        <Box borderRadius='50%' width='24px' height='24px' backgroundColor={details.glyphColor}>
          {details.glyph === 'info' && <StyledIcon src='/assets/images/icon-info-white.svg' />}
          {details.glyph === 'check' && <StyledIcon src='/assets/images/icon-check-white.svg' />}
          {details.glyph === 'x' && <StyledIcon src='/assets/images/icon-x-white.svg' />}
          {details.glyph === 'exclamation' && <StyledIcon src='/assets/images/icon-exclamation_mark-white.svg' />}
          {details.glyph === 'calendar' && <StyledIcon src='/assets/images/icon-calendar-white.svg' />}
          {details.glyph === 'comment' && <StyledIcon src='/assets/images/icon-comment-white.svg' />}
          {details.glyph === 'star' && <StyledIcon src='/assets/images/icon-star-white.svg' />}
        </Box>
      </Flex>
      <Flex flexDirection='column'>
        <Text as='span' size='xs' color='inherit'>
          {moment.utc(notification.created_at).fromNow()}
        </Text>
        <Text as='span' size='s' color={isRead ? 'mediumGray' : 'darkGray'}>
          {notification.body}
        </Text>
      </Flex>
    </StyledContainer>
  )
}
