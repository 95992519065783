import { cast } from 'helpers/schema'
import * as yup from 'yup'

export const alcoholSchema = yup.object({
  id: yup.number().optional(),
  title: yup.string().nullable().default(null),
  original_title: yup.string().required(),
  description: yup.string().nullable().default(null),
  is_alcoholic: yup.boolean().default(true),
})

export function create(data) {
  return cast(alcoholSchema, data)
}

export const emptyAlcohol = create()
