import React from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { getCountryFromIPAddress } from 'redux/selectors'
import IconMasterchef from '@vizeat/components/es6/assets/icons/IconMasterchef'

export function useLandingPagesLinks({ hideAllNavItems, shouldDisplayBecomeAHostButton }) {
  const { t } = useTranslation()
  const router = useRouter()
  const ipCountry = useSelector(getCountryFromIPAddress)
  const shouldShowMasterchefLink = ['US', 'MX', 'CA'].includes(ipCountry) && router.pathname !== '/l/masterchef'

  if (hideAllNavItems) return { masterchef: undefined, becomeHost: undefined }

  return {
    masterchef: shouldShowMasterchefLink && {
      href: '/l/masterchef',
      text: (
        <Trans
          i18nKey={__('Header::<masterchefIcon /> MasterChef online')}
          components={{ masterchefIcon: <IconMasterchef mr='4px' /> }}
        />
      ),
    },
    becomeHost: shouldDisplayBecomeAHostButton && {
      href: '/landings/become-host/become-a-host-on-eatwith',
      text: t('Header::Become a host'),
    },
  }
}
