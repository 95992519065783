import { cast } from 'helpers/schema'
import * as yup from 'yup'

export const dietSchema = yup.object({
  id: yup.number().optional(),
  title: yup.string(),
  description: yup.string().nullable().default(null),
  original_title: yup.string().required(),
})

export function create(data) {
  return cast(dietSchema, data)
}

export const emptyDiet = create()
